<template>
  <a-modal
    title="用户数据授权"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel">
    <a-spin :spinning="loading">

      <a-tree
        style="height: 500px;overflow-y: auto"
        default-expand-all
        v-model="currentIds"
        :blockNode="true"
        checkable
        :check-strictly="true"
        :tree-data="treeData"
        :key="treeDataKey"
        :replaceFields="{children:'children', title:'name', key:'deptId' }"
      >
        <div slot="nodeData" slot-scope="record" :class="record.key+'1'" :style="setTreeDom(record)">
          <div>{{ record.name }}</div>
        </div>
      </a-tree>
    </a-spin>
  </a-modal>
</template>

<script>
import { getDeptByUserId, dataAccredit } from '@/api/user-rights/usermanage'

export default {
  name: 'AcDataAuthorize',
  data () {
    return {
      visible: false,
      confirmLoading: false,
      loading: false,
      userId: null,
      treeData: [], // 树信息
      currentIds: { checked: [] }, // 选中信息
      treeDataKey: 1,
      disabledIds: [] // 禁用信息
    }
  },
  methods: {
    /**
     *编辑打开
     */
    edit (record) {
      this.userId = record.userId
      this.handleUserTree(record.userId)
      this.visible = true
    },
    /**
     * 取消并关闭
     */
    handleCancel () {
      this.userId = null
      this.visible = false
      this.treeData = []
      this.currentIds = { checked: [] }
      this.disabledIds = []
    },
    /**
     * 提交
     */
    handleSubmit () {
      this.confirmLoading = true
      const role = JSON.parse(JSON.stringify(this.currentIds.checked))
      // 删除禁用信息
      for (let i = 0; i < role.length; i++) {
        if (this.disabledIds.includes(role[i])) {
          role.splice(i, 1)
        }
      }
      dataAccredit({ userId: this.userId, deptIds: role }).then((res) => {
        this.confirmLoading = false
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.handleCancel()
          this.$emit('ok', role)
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },
    /**
     * taidi
     * 获取用户数据
     */
    handleUserTree (userId) {
      this.loading = true
      getDeptByUserId({ userId: userId }).then((res) => {
        if (res['code'] === 200) {
          const disabledIds = res.data['roleDeptIds']
          // 设置选中
          this.currentIds.checked = res.data['userDeptIds']
          this.recursion(res.data['spDeptVoList'], disabledIds)
          if (disabledIds.length > 0 && Array.isArray(res.data['spDeptVoList'])) {
            // 禁用同时设置选中
            for (let i = 0; i < disabledIds.length; i++) {
              this.currentIds.checked.push(disabledIds[i])
            }
            // 保存禁用字段
            this.disabledIds = disabledIds
          }
          this.treeData = res.data['spDeptVoList']
          this.treeDataKey++
        }
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     * 递归添加禁用
     * @param data
     */
    recursion (data, disabledIds) {
      const children = []
      for (let i = data.length - 1; i >= 0; i--) {
        const item = data[i]
        item.scopedSlots = { title: 'nodeData' }
        if (i === 0) {
          for (let i = children.length - 1; i >= 0; i--) {
            data.push(children.pop())
          }
        } else if (item.children) {
          children.push(item)
          data.splice(i, 1)
        }
      }
      _.map(data, item => {
        if (disabledIds.includes(item.deptId)) {
          item.disabled = true
          item.name = item.name + '(角色已选择)'
          return item
        }
        if (item.children) {
          this.recursion(item.children, disabledIds)
        }
      })
    },
    /**
     * 设置树dom样式
     */
    setTreeDom (record) {
      if (record.children) {
        this.$nextTick(() => {
          const thisDomElement = document.getElementsByClassName(record.key + '1')[0].parentNode.parentNode.parentNode
          thisDomElement.classList.add('line')
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/styles/modal";

/deep/ .ant-tree-treenode-switcher-open {
  display: inline-block;

}

/deep/ .line {
  display: block !important;
}

/deep/ .ant-tree li ul {
  //word-wrap: break-word;
  word-wrap: normal;
  //word-break: normal;
  word-break: keep-all;
  width: 100%;
}

/deep/ .ant-tree li {
  white-space: unset !important;
}
</style>
